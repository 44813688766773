import React from "react"
import logo from "../../imgs/logo.png"
import { Layout, Breadcrumb, Menu, Row, Col, Divider } from 'antd';
import { Link, useStaticQuery, graphql, navigate } from 'gatsby';
import { useMediaQuery } from 'react-responsive';

const { Header, Content, Footer } = Layout;

let logoStyle = {
  width: 120,
  height: 31,
  background: "rgba(255, 255, 255, 0.2)",
  margin: "16px 24px 16px 0",
  float: "left",
  lineHeight: 0
}

export default ({ children, breadcrumbItems }) => {
  const data = useStaticQuery(
    graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `
  )
  const title = data.site.siteMetadata.title

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

  if (isMobile) console.log("AAAAAA")


  const mainContentCSS = isMobile ? { padding: '0 5px' } : { padding: '0 50px' }
  const cCSS = { background: '#fff', minHeight: 280 }
  const contentCSS = isMobile ? { ...cCSS, padding: 20 } : { ...cCSS, padding: 24 }

  return (
    <Layout className="layout" style={{ minHeight: "100vh" }}>
      <Header>
        <div style={logoStyle} >
          <Link
            to={"/"}
            css={{ textDecoration: "none" }}>
            <img src={logo} style={{ width: 120, height: 31 }} alt={title} />
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          selectable={false}
          style={{ lineHeight: '64px' }}
          onClick={function bla(item) {navigate(item.key)}}
        >
          
            <Menu.Item key="/">Home</Menu.Item>
          
            <Menu.Item key="/about-us/">About us</Menu.Item>
          
            <Menu.Item key="/support-us/">Support us</Menu.Item>
          
        </Menu>
      </Header>
      <Content style={mainContentCSS}>
        <Breadcrumb style={{ margin: '16px 0' }}>

          <Breadcrumb.Item>
            <Link
              to={"/"}
              css={{ textDecoration: "none" }}>
              Home
              </Link>
          </Breadcrumb.Item>

          {breadcrumbItems}
        </Breadcrumb>
        <div style={contentCSS}>
          {children}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <Row><Col>Join us on <Link to={"/discord/"} css={{textDecoration:"none"}}>Discord</Link></Col></Row>
        <Divider style={{margin: "0px 0"}}/>
        <Row><Col>MrLTactics © 2019 Created by Luigi Frunzio</Col></Row>
        
        </Footer>
    </Layout>
  )
};
